body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar { 
  display: block; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headtool { display: flex; justify-content: space-between;}
.app-tool { background: linear-gradient(90deg, rgba(255,255,255,1) 14%, rgba(184,237,247,1) 100%);background-color:#fff !important; top: 0; position: fixed !important; z-index: 99;    box-shadow: 0 2px 5px #0000004d !important;}
.button-group button { margin:0px 2px; border: 1px solid #000; color: #000;    text-transform: capitalize;}
p {
  font-size: 15px;
  font-weight: normal;
  color: #677a85;
  margin-bottom: 15px;
  line-height: 23px;
}
  
/* header */

.header {
  width: 100%;
  z-index: 3;
}
.header .menu {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.header .active {color: #0d6efd;}
.header a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  position: absolute;
    width: auto;
    top: 0;
}
.book { color: #ff0000 !important;}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 300px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */
@media (max-width: 991px) {
  
.nav-link:focus, .nav-link:hover {
  color: #fff !important;
}
.navbar-nav .dropdown-menu { background: #000 !important;
  padding-left: 20px !important;
}
.dropdown-item a{padding: 0px !important;}
.collasible-nav-dropdown { padding: 13px 20px !important;}
.nav-link a{ padding: 0px !important;}
  .navbar { position: inherit !important;}
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    top: 55px;
    background: #000;
    width: 100%;
    left: 0;
}
  .navbar-toggler { float: right;
    border: none !important;}
    
  .header a {
    padding: 5px 20px;
  }
  .header a { color: #fff !important; display: block;}
  .header .menu {
    background: #000;
    width: 100%;
    top: 60px;
    right: 0;}
    .header .menu-icon{ float: right;}
.menuu-block { width: 50%;}

.dropdownmenu {
  display: none;
  margin-top: 2px !important;
  background: #000 !important;
  margin-left: -20px !important;
  padding-left: 20px !important;
  z-index: 99 !important;
  box-shadow: none !important;
  padding-bottom: 32px !important;
  width: 100% !important;
  max-height: 300px !important;
  overflow: scroll !important;
}
.dropdownmenu a {
  border-bottom: none !important;
  font-size: 15px !important;
}
.header .active {
  color: #fff !important;
}

}
@media (max-width: 767px) {
  
.stacking { display: block !important;}
.adblocks .css-e53awj-MuiStack-root { width: 100% !important; display: block !important;}
.adblocks .MuiPaper-root{ width:100% !important; 
  margin-left: 0px !important;}
.ch-img{ width: 100%; margin-bottom: 20px;}
  #collasible-nav-dropdown {
    line-height: 22px;
}
.nav-link:focus, .nav-link:hover {
  color: #fff !important;
}
.navbar-nav .dropdown-menu { background: #000 !important;
  padding-left: 20px !important;
}
.dropdown-item a{padding: 0px !important;}
.collasible-nav-dropdown { padding: 13px 20px !important;}
.nav-link a{ padding: 0px !important;}
  .navbar { position: inherit !important;}
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    top: 55px;
    background: #000;
    width: 100%;
    left: 0;
}
  .navbar-toggler { float: right;
    border: none !important;}
  .app-tool{ top: -1px;}
  .contact-area{ width: 100% !important;}
  .header a {
    padding: 5px 20px;
  }
  .header a { color: #fff; display: block;}
  .header .menu {
    background: #000;
    width: 100%;
    top: 60px;
    right: 0;}
    .header .menu-icon{ float: right;}
.menuu-block { width: 50%;}
.homecarousal {
  margin-top: 58px !important;
}
.abtbl .mb-80{
  margin-bottom: 14px !important;}

  .abtbl h2 { font-size: 36px !important;}
.abtbl .features__wrapper-3 .r-btn { margin-bottom: 30px;}
.range .features__item { display: flex !important;}
.description { padding: 100px 0px 0px 0px !important;}
.adblocks {  margin-top: 30px !important;}
.homepage-form { margin-top: 40px;}
.social {
  text-align: left !important;
  padding-left: 0px !important;
}
.ph-cont { display: block !important;}
.ph-block { width: 100% !important;}
.dropdownmenu {
  display: none;
  margin-top: 2px !important;
  background: #000 !important;
  margin-left: -20px !important;
  padding-left: 20px !important;
  z-index: 99 !important;
  box-shadow: none !important;
  padding-bottom: 32px !important;
  width: 100% !important;
  max-height: 300px !important;
  overflow: scroll !important;
}
.dropdownmenu a {
  border-bottom: none !important;
  font-size: 15px !important;
}
.header .active {
  color: #fff !important;
}
.sidebarleft {  height: auto !important;}
.header .menu-btn:checked ~ .menu {
  max-height: 100vh;
  height: 100vh;
}
.application-form {
  padding: 20px !important;
  border: 1px solid #d3d3d3 !important;
  margin: 10px !important;}
  .contentblock { padding: 26px 12px !important;}
  .font-36 { font-size: 25px !important;}




}
#collasible-nav-dropdown { line-height: 30px;}
@media (min-width: 768px) {

  .header{
    float: left; 
  }
  .header a {
    padding:3px 4px;
    font-size: 14px;
    font-weight: 500;
    color: #000;text-decoration: none; display: inline-block;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
   
    top: 0;
    width: auto;
    right: 34px;
  }
  .header .menu-icon {
    display: none;
  }
}


@media (max-width: 768px) {
.navbar-nav .dropdown-menu {
  position: static;
  height: 200px !important;
  overflow: auto;
}
}

.homecarousal { margin-top: 64px;}
.features__wrapper-border {
  position: relative;
}
.mb-80 {
  margin-bottom: 80px;
}
.section__title-pre-3.right {
  padding-right: 45px;
  padding-left: 0;
}
.section__title-pre-3 {
  color: #677a85;
}
.section__title {
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;
}
.features__wrapper-border::after {
  position: absolute;
  content: "";
  right: 10px;
  top: 0;
  width: 2px;
  height: 140px;
  background: #e1e1e1;
}
.features__wrapper-3 p {
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
  color: #677a85;
}
.section__title-pre-3.right::after {
  left: auto;
  right: 0;
  background: #677a85;
}
.features__area {
  padding: 100px 0px;}
  .pr-45 {
    padding-right: 45px;
}
.r-btn {
  border: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: #fc8459;
  height: 60px;
  line-height: 62px;
  text-align: center;
  padding: 0 38px;
  text-transform: uppercase;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-decoration: none;
}
.r-btn-grey:hover {
  color: #ffffff;
}
.r-btn-grey {
  background: #1E90FF;
  color: #fff;
}
.features__item-3 {
  padding: 50px 35px;
  -webkit-box-shadow: 1px 0px 5px -1px rgba(122,122,122,1);
  -moz-box-shadow: 1px 0px 5px -1px rgba(122,122,122,1);
  box-shadow: 1px 0px 5px -1px rgba(122,122,122,1);
  max-height: 480px;
  height: 400px;
}
.grey-bg {
  background: #f8f8f8;
}
.mb-30 {
  margin-bottom: 30px;
}
.text-center {
  text-align: center!important;
}
.p-relative {
  position: relative;
}
.mb-30 {
  margin-bottom: 30px;
}
.features__icon-3 span {
  display: inline-block;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 60px 0px rgb(207 199 188 / 47%);
  -moz-box-shadow: 0px 10px 60px 0px rgba(207, 199, 188, 0.47);
  box-shadow: 0px 10px 60px 0px rgb(207 199 188 / 47%);
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  -moz-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  -ms-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  -o-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
}
.features__title-3 {
  margin-bottom: 15px;
}
.features__title {
  font-size: 21px;
  margin-bottom: 16px;
}
.features__content-3 p {
  margin-bottom: 25px;
  font-size: 14px;
  color: #677a85;
  line-height: 24px;
}
a:focus, a:hover {
  color: #2c3941;
  text-decoration: none;
}
.features__title a {
  color: #2c3941; text-decoration: none;}
  .fbg {
    background-image: url("http://geton.co.in/kamdhenupaints/images/features-bg.jpg");}
    .features__item:not(:last-child) {
      margin-bottom: 5px;
      padding-bottom: 7px;
      border-bottom: 1px solid #dedede;
  }
  .features__icon span {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 55px;
    text-align: center;
    background: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
    -moz-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
    -ms-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
    -o-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
    transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
}
.features__icon span img {
  width: 35px;}
.features__title {
  font-size:19px;
  margin-bottom: 5px;
}
.features__content p {
  margin-bottom: 0;
}
.mr-30 {
  margin-right: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.portfolio__bg {
  position: relative;
}
.pb-100 {
  padding-bottom: 100px;
}
.p-relative {
  position: relative;
}
.portfolio__thumb-2 {
  position: relative;
}
.w-img img {
  width: 100%;
}
.portfolio__thumb-2::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 76, 72, 0.75);
  visibility: hidden;
  opacity: 0;
}
.portfolio__item-2:hover .portfolio__content-2 {
  visibility: visible;
  opacity: 1;
  top: 50%;
}
.portfolio__content-2 {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
}

.portfolio__title {
  font-size: 22px;
}
.portfolio__title {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 5px;
}
.portfolio__content-2 p {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0;
}
.ptb-100 { padding: 100px 0px;}
.address .MuiSvgIcon-root{ 
  font-size: 20px;
  color: #000;
  display: inline-block;
  margin-right: 10px;}
.address strong { display: block;}
.adblock { text-align: center;}
.address .section__title {
  font-size: 32px;
  line-height: 1.1;
  font-weight: 500;
  text-align: center;
  margin-bottom: 77px;
  display: block;
}
.form-inner input, .form-inner textarea {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  transition: all .5s ease;
}
.left-footer { display: flex; }
.footer p, .footer a {
    color: #000 !important;
    font-size: 14px;
    margin: 4px 5px;
    text-decoration: none !important;}
.footer {padding: 12px 0px; background: #fff; -webkit-box-shadow: -1px 0px 5px -1px rgba(112,105,112,1);
  -moz-box-shadow: -1px 0px 5px -1px rgba(112,105,112,1);
  box-shadow: -1px 0px 5px -1px rgba(112,105,112,1);}
.social { text-align: right;
  margin-bottom: 0px;}
.social li { 
  list-style: none;
  display: inline-block;
  margin: 0px 8px;
  border-radius: 5px;
  background: #fff;
  padding: 2px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);}
.social .MuiSvgIcon-root{ color: #fff !important;}
.contentblock { margin-top: 60px; padding: 70px 0px;}
.tableblock table { border: 1px solid #fff; width: 100%; font-size: 14px;}
.tableblock table tr { border-bottom: 1px solid #F0F8FF;
  display: flex;
  justify-content: space-between;}
.tableblock table td {
  color: #000;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;}
.tableblock table td table td { padding: 5px 0px ;}
.tableblock table tr:nth-child(odd) {background: #F8F8F8}
.font-36 { font-size: 24px;}
.sidebarleft {background: #DCDCDC; height: 100vh;border-left: 4px solid #999;}
.sidebarleft .MuiButtonBase-root{
  margin: 0;
  padding: 0px 10px;
  font-size: 7px;}
.sidebarleft .MuiTypography-root { font-size: 15px;}
.download a { color: #ff0000; text-align: right; float: right;}
.dropdownmenu { display: none;}
.activedropdown:hover .dropdownmenu { display: block !important;}
.activedropdown{ position: relative;}
.dropdownmenu {  
  display: none;
  margin-top: 20px;
  position: fixed;
  background: #fff;
  width: auto;
  margin-left: -14px; box-shadow: 1px 0px 5px -1px rgb(122 122 122);}
  .dropdownmenu a{ 
    display: block !important;
    padding: 4px 12px;
    border-bottom: 1px solid #d3d3d3;
    font-size: 13px;}
    .mb-20 { margin-bottom: 20px;}
  #chairman-msg strong {
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
    display: block;
    background: linear-gradient(to left, #FF0000 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}
.chairman-msg img {box-shadow: 1px 0px 5px -1px rgb(122 122 122);}
    #chairman-msg .features__wrapper-3 p {
      font-size: 15px;
      line-height: 24px;
  }
  #philosophy {background: linear-gradient(to right, rgb(22, 160, 133), rgb(244, 208, 63));}
  #philosophy p{ color: #fff;  margin-bottom: 42px;}
  #philosophy h2{ color: #fff;}
  .pb-100 { padding-bottom: 100px;}
  .ph-cont { display: flex;}
  .ph-block { width: 20%; border: 1px solid #d3d3d3; padding: 20px; margin: 5px; background: #fff;}
  .ph-block img { width: 70px;}

  
  #business-model {
    background-image: url("http://geton.co.in/kamdhenupaints/images/colorshades.png");
    background-repeat: no-repeat;
    background-position: right center;
  
  }
 #awareness .features__item-3 {
    padding: 17px 35px;
    box-shadow: 1px 0px 5px -1px rgb(122 122 122);
    max-height: 458px;
    height: 306px;
}
#awareness .features__item-3 h3, #awareness .features__item-3 p { color: #fff !important;}
.vision-block { background: #f2f2f2; padding: 20px;}
.listing { padding-left: 0px;}
.listing li { list-style: none;}
#vission-mission .MuiSvgIcon-root {
  color: #ff0000;
  font-size: 33px;}
  #vission-mission li strong { color: #666;}

#directors .features__item-3{ box-shadow: none !important;
  height: 440px;
  padding: 10px 10px !important;}
#directors .features__item-3 small { 
  display: block;
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 16px;}
#directors .features__icon-3 span{ overflow: hidden !important;}
#directors .features__icon-3 span img { width: 100%;}
#committies {
  background: url(https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000);
  background-size: cover;
}
#committies table {box-shadow:0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)}
table th {
  padding: 3px 10px;}
  .tableblock table tr:nth-child(even) {
    background: transparent;
}
#committies .tableblock table tr:nth-child(odd) {
  background: transparent;
}
#awards img { width:100%;}
.adblocks { text-align: left;}
.adblocks p { margin-bottom: 5px;  display: flex;}

.req-dialog .login-btn { justify-content: flex-start !important;
  padding: 0px 20px 20px !important;}
.req-dialog .close { position: absolute; right: 0;}
.req-dialog h3{
font-size: 20px;
padding: 20px 20px 0px 32px;}
.req-dialog .dilogcont{
padding: 3px 20px 0px 32px;}
.req-dialog .MuiDialogContent-root { margin-top: -35px;}
.wd-100 { width: 100%;}
.application-form .MuiFormControl-root{ width:100%; margin-bottom:13px;}
.select-box .MuiFormLabel-root { left: -14px !important;}
.select-box .MuiFormLabel-root, .application-form .MuiFormLabel-root{ font-size: 14px;} 
.mt-40 { margin-top:30px !important;}
.application-form p{
  margin-bottom: 0px;
  margin-top: 5px;}
  .application-form h5 { margin-bottom: -10px;}
  .ct-img { width: 100%; height: 100%;}
.adblocks .MuiDivider-root {
  margin: 0px 0px 52px 0px !important;
  border-color: rgba(0, 0, 0, 0.99);}
  
.contact {
  background: linear-gradient(to right, rgb(255, 81, 47), rgb(240, 152, 25));    padding: 80px 0px;}
.ctcard{ padding: 15px !important; background:transparent !important; border:1px solid #fff}
.contact h2, .contact p { color: #fff;}
.phone-block { display: block !important;}
.mt-30 { margin-top: 30px;}
.mt-20 { margin-top: 20px;}
.disabled-link {
  pointer-events: none;
}
.non-active{color:#000 !important}
.dropdown-item.active a, .dropdown-item:active a { color: #0d6efd !important; }
.dropdown-item.active , .dropdown-item:active { background: #eee !important;}
.bg-salmon {
  color: #0d6efd !important;
}
.top-para { margin-top: 25px;}
.paint-block{ margin-bottom: 20px;}
  .paintdes {
    height: 498px;
    max-height:500px;
    background: #f5f5f5;
    padding: 150px 20px 20px 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    text-align: center;
}
.paint-box {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  /* width: 40%; */
  /* background: #E6E6FA; */
  margin-bottom: -150px;}

.paintdes p {
  color: #505050;
  font-size: 14px;
  line-height: 24px;}

  .addressimg {
    width: 45px;
    display: -webkit-box;
    margin: 0 auto;
    margin-bottom: 10px;}
    .adblocks .MuiPaper-root { width: 33%;
      box-shadow: none;}

  .adblock .MuiPaper-root b{
  display: block;
  color: #000;}
  .tableblock .adblocks { padding: 0px 20px;}
.tableblock .adblocks .MuiSvgIcon-root { color: #000; margin-right: 10px;}
.web-name {
  color: #ff0000;
  font-size: 18px;
  margin-bottom: 10px !important;}
.address-heading h5{
  background: #00587B;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  margin:0px 0px 10px 0px;}
  .address-heading {
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 0px !important;
    overflow: hidden;
    margin-top: 30px !important;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
     rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;}
     .contact-table {margin-bottom: 10px;}
.contact-table tr {
  border-bottom: 1px solid #d3d3d3 !important; 
  color: #677a85;
}
.contact-table tr:nth-child(odd) {
  background: #fff !important;
}
.contact-table tr td:first-child{
  font-weight: 600;border-right: 1px solid #d3d3d3 !important; color: #ff0000 !important; }
  .contact-table .adblocks { padding: 0px !important;}
  .contact-table .adblocks p{ font-size: 14px !important;}
.address h5 { text-transform: uppercase;}

.pick-colors .MuiTypography-root {
  font-size: 18px;
  text-align: left;}

  .pick-colors .MuiCardContent-root {padding-bottom: 8px !important;}
  .fitem-1 {    
    color: #fff;
    background: linear-gradient(to right, rgb(192, 72, 72), rgb(72, 0, 72)) !important;}
.fitem-2 { 
  color: #fff;
  background: linear-gradient(to right, rgb(95, 44, 130), rgb(73, 160, 157)) !important;}
.fitem-3 {
  color: #fff;
  background: linear-gradient(to right, rgb(235, 51, 73), rgb(244, 92, 67)) !important;}
  .fitem-1 p, .fitem-2 p, .fitem-3 p {
    color: #fff;
}
.formbtnn {
  background-color: #d40404;
  padding: 10px 30px;}

.facebook {background: #4267B2 !important;}
.twitter {background: #1DA1F2 !important;}
.instagram {background: #E1306C !important;}
.linkedin {background: #405DE6 !important;}
.youtube {background: #FF0000 !important;}
.f-1{background: linear-gradient(to right, rgb(22, 160, 133), rgb(244, 208, 63)) !important;}
.f-2{background: linear-gradient(to right, rgb(185, 43, 39), rgb(21, 101, 192)) !important;}
.f-3{background: linear-gradient(to right, rgb(31, 64, 55), rgb(153, 242, 200)) !important;}
.f-4{background: linear-gradient(to right, rgb(101, 153, 153), rgb(244, 121, 31)) !important;}
.f-5{background: linear-gradient(to right, rgb(230, 92, 0), rgb(249, 212, 35)) !important;}
.f-6{background: linear-gradient(to right, rgb(204, 43, 94), rgb(117, 58, 136)) !important;}
.product-btn {
  padding: 10px 22px !important;
  color: #fff !important;
  border-radius: 21px !important;
  font-size: 11px !important;
  background: -webkit-gradient(linear, left top, right top, from(#ed1258), to(#3d02c1));
  background: -webkit-linear-gradient(left, #ed1258 0%, #3d02c1 100%);
  background: -o-linear-gradient(left, #ed1258 0%, #3d02c1 100%);
  background: linear-gradient(to right, #ed1258 0%, #3d02c1 100%);
  }
.paintdes h5 {
  background: -webkit-gradient(linear, left top, right top, from(#ed1258), to(#3d02c1));
  background: -webkit-linear-gradient(left, #ed1258 0%, #3d02c1 100%);
  background: -o-linear-gradient(left, #ed1258 0%, #3d02c1 100%);
  background: linear-gradient(to right, #ed1258 0%, #3d02c1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;}
.pdt-0{ padding-top: 0px !important;}
.banner-area{ margin-bottom:70px; 
  height: 350px;
  overflow: hidden;}
.mb-10{ margin-bottom: 10px;}
.paintdes small {
  font-size: 14px;
  font-weight: 600;
  margin: -5px 0px 16px 0px;
  display: block;}

.privacycontent li{
    font-size: 15px;
    margin-bottom: 5px;}
.privacycontent p {color: #000;
    margin: 5px 0px 10px 0px;}
    .privacycontent strong { display: block; }

    .navbar-expand-lg .navbar-nav .dropdown-menu{ height: auto;}
    .link a { color: blue !important; text-decoration: none;}

.prhead{
  background: linear-gradient(to right, #ed1258 0%, #3d02c1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;}



  .procuts-info {
    position: relative;
    height: 100%;
    width: 100%;
    background: url('https://kamdhenupaints.com/images/p-info-bg.jpg') no-repeat center center;
    background-size: cover;
}
  .procuts-info .info-overlay {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: block;
}
.pro-bg { position: relative;}

.pro-bg .paint-block{
background: #f5f5f5;}

.pro-bg .paintdes {
  height: auto;
  max-height: auto;}

  .pro-bg .paintdes h5{ font-size: 17px;}
  .pro-bg .ct-img{ margin-left: -15px;
    width: 60%;}
  .dwnpdf {
    text-decoration: none;
    font-size: 15px !important;
    display: inline-block;
    margin: 5px;
}
.accor .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{ min-height: 32px !important;}
.accor .MuiTypography-root{
  color: #212529;
  line-height: 15px;
  font-size: 13px;
  margin-left: -4px;
  text-transform: uppercase;
  font-weight: 600;}

  .accor .MuiCollapse-root .MuiTypography-body1{ color: #787878 !important;}
  .listttt li{
    font-size: 15px;
    font-weight: normal;
    color: #677a85;
    margin-bottom: 2px;
    line-height: 23px;}


    
.faq .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.faq .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  background: #fff;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.faq .tab-label:hover {
  background: #1a252f;
}
.faq .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.faq .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.faq .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.faq .tab-close:hover {
  background: #1a252f;
}

.faq input:checked + .tab-label {
  background: #1a252f;
}
.faq input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.faq input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}
.download{
  display: flex;
}



.faqq {
	margin-bottom: 12px;
	transition: 0.3s all;
	border: 1px solid #d9e9ff;
	
	border-radius: 2px;
}

.faq_active {
	margin-bottom: 12px;
}

.faq__question {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 41px;
  padding: 6px 13px;
  background-color: #fff;
  transition: 0.3s all;
  cursor: pointer;
}

.faq__question:hover {
	background: #f6fbff;
}

.faq_active .faq__question {
	background: #f6fbff;
}

.faq__ico {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.faq__ico_m_r {
	margin-right: 13px;
}

.faq__ttl {
	color: #32325c;
	font-size: 15px;
	font-weight: 600;
	flex-grow: 1;
}

.faq__close {
  width: 10px;
  height: 10px;
	flex-shrink: 0;
	position: relative;
	cursor: pointer;
}

.faq__close:before {
	content: "";
	display: block;
	height: 2px;
	background-color: #32325c;
	position: absolute;
	top: calc(50% - 1px);
	left: 0;
	right: 0;
	transition: 0.3s all;
}

.faq_active .faq__close:before {
	transform: rotate(180deg);
}

.faq__close:after {
	content: "";
	display: block;
	width: 2px;
	background-color: #32325c;
	position: absolute;
	top: 0;
	left: calc(50% - 1px);
	bottom: 0;
	transition: 0.3s all;
}

.faq_active .faq__close:after {
	transform: rotate(180deg);
	opacity: 0;
}

.faq__answer {
	border-radius: 2px;
	background-color: #fff;
	padding:17px 13px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	display: none;
}

.faq__txt {
	color: #5b6787;
  font-size: 15px;
  font-weight: 400;
}

.faq__accent {
	color: rgb(254, 185, 84);
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 2px;
}
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}
.reviews {padding-bottom: 80px;}
.reviews .carousel-item{
  background: #f1f1f1;
  padding: 30px;}

  .reviews .carousel-item p{
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: #000;
  margin-bottom: 30px;
  font-style: italic;
  line-height: 28px;
  }
  .blog .features__item-3{ 
    padding: 0px;
    border-radius: 5px;
    overflow: hidden;}
  .blog .features__item-3 img { width: 100%;}
  .blog .features__content-3 p {
    margin-bottom: 10px;
    font-size: 14px;
    color: #677a85;
    line-height: 24px;
}
.paintdes a {color:#fff !important; text-decoration: none !important;
  white-space: nowrap;
  color: #fff !important;
  text-decoration: none !important;
 }
.ct2 {
  width: 100%;
  padding: 0px;
}
.ct2 .ct-img {
  margin-left:0px;
  width: 80%;
}
.features__icon-3.mb-30{ margin-bottom: 8px !important;}
.sidebloglist { border: 1px solid #d3d3d3; border-radius: 5px; padding: 15px;}
.sidebloglist strong{
  font-weight: 500;
  line-height: 20px;
  display: block;
  font-size: 15px;}
  .sidebloglist h6{ margin-bottom: 20px;}
  .sidebloglist hr{
    margin: 10px 0px;
    border-top: 1px dashed;}

    .sidebloglist a { color: #000; text-decoration: none;}
.blog .features__item-3{ min-height: 300px; height: auto;}
.blog .features__title {
  font-size: 15px;
  padding: 0px 10px;
  margin-bottom: 17px;
}
.blog p {margin-bottom: 5px;}
.bdetails h5{
  font-size: 28px;
  margin-bottom: 16px;
  display: block;}
  .news .features__item-3 {
    min-height: auto !important;
    height: auto;
}